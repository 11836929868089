import Vue from 'vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import './assets/themes.less'
import App from './App.vue'
import router from './router'
import store from './store'

import service from './api';
import mock from './api/mock';

Vue.config.productionTip = false
Vue.use(Antd);
//Vue.use(mock)
Vue.prototype.$service = service;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
