<template>
    <div class="top-header">
        <div class="logo" >&nbsp;</div>
        <a-menu
            theme="dark"
            mode="horizontal"
            :default-selected-keys="['2']"
            :style="{ lineHeight: '62px' }"
            :selectedKeys="[1]"
        >            
            <template v-for="(item,index) in menuData">
                <a-menu-item  v-if="item.children.length==0" :key="item.id">
                    <a :href="item.link">{{item.title}}</a>
                </a-menu-item>
                <a-sub-menu v-else>
                    <span slot="title" class="submenu-title-wrapper">{{item.title}}</span>
                    <a-menu-item v-for="(childItem,childIndex) in item.children" :key="childItem.id" >
                        <a :href="childItem.link">{{childItem.title}}</a>
                    </a-menu-item>
                </a-sub-menu>
            </template>
        </a-menu>
    </div>
</template>
<script>
import {mapState, mapMutations } from 'vuex'
export default {
    name:'sk-header',
    data(){
        return{
            // menuData:[],
            curMenu:1
        }
    },
    computed:{
        ...mapState({
            menuData:state=>state.menus
        })
    },
    created(){
        this.loadMenus();
        // console.log(this.menuData);
    },
    mounted() {
        // this.loadMenus();
    },
    methods:{
        ...mapMutations(['setMenus']),
        loadMenus(){
            // this.$service.get('getMenus')
            this.$service.Ers76.listNav({"status":1,"type":1}).then(resp=>{
                console.log("header listNav",resp);
                // let data=resp.data.filter(m=>m.parentId==0);
                // data.forEach(item => {
                //     item.children=resp.data.filter(m=>m.parentId==item.id);
                // });
                // //this.menuData=data;
                // this.setMenus(data);
                if(resp && resp.data)
                {
                    // this.menuData = resp.data;
                    // this.setMenus(resp.data);
                    let data = [];
                    for(let i = 0; i < resp.data.length; i++)
                    {
                        resp.data[i]["children"] = [];
                        data.push(resp.data[i]);
                    }
                    this.setMenus(data);
                    // console.log("after menu data",this.menuData);
                }

            }).catch(err => {
                console.log(err);
            });
        }
    }
}
</script>
<style lang="less" scoped>
    .top-header .ant-menu{display: flex;justify-content: flex-end;}
    .ant-menu-item-selected > a::after{
        content: "";display: block;width:4px;height:4px;background-color: @primary-color;
        position: absolute;left: 50%;bottom:10px;border-radius: 50%;
    }
</style>