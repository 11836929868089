import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  //plugins: [createPersistedState()],
  state: {
    asynRouters:[],
    menus:[]
  },
  mutations: {
    setAsynRouters(state,routers){
      state.asynRouters=routers;
    },
    setMenus(state,menuData){
      state.menus=menuData;
    }
  },
  actions: {
  },
  modules: {
  }
})
