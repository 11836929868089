import Mock from "mockjs";
import mockAPI from "./mockAPI";
import modules from "../modules";
const apiPrefix = process.env.VUE_APP_API_Prefix;

Mock.setup({
    timeout: "500-1000",
  });
//Mock.mock('/api'+modules.getMenus, 'get', mockAPI.getMenus());
for(let key in modules){
  Mock.mock(RegExp(apiPrefix+modules[key] + ".*") ,  mockAPI[key]());
}
