import Mock from "mockjs";
export default{
    getRoutes(){
        return{
            code:200,
            message:'success',
            data:[{name:'home',path:'/home'}]
            
        }
    },
    getMenus() {        
        return {
            code: 200,
            message: 'success',
           'data|12':[
                {                    
                    'id|+1': 1, // 自增id
                    title: '@ctitle(3, 5)', // 随机生成中文名字
                    parentId:function(){
                        return this.id<=5?0:Mock.mock('@integer(2,5)')
                    },
                    link:'/home',
                }
            ],
        }
    },
    getPageModules(){
        return{
            code: 200,
            message: 'success',
           'data|4':[
                {                    
                    id: '@increment(1)', // 得到随机id
                    'name|+1':[
                         'swipper',
                           'abount',
                           'caseIntroduction',
                           'caseIntroduction2'
                    ]
                }
            ],
        }
    },
    getSwippers(){
        return{
            code: 200,
            message: 'success',
           'data|4':[
                {                    
                    id: '@increment(1)', // 得到随机id
                    title: '@ctitle(10, 20)', // 随机生成中文名字
                    subTitle:'@ctitle(10, 20)',
                    desc:'@ctitle(10, 20)',
                    'imgUrl|+1':[
                        'http://192.168.0.200:8070/content/img/swiper/1.jpeg',
                        'http://192.168.0.200:8070/content/img/swiper/2.jpeg',
                        'http://192.168.0.200:8070/content/img/swiper/3.jpeg',
                        'http://192.168.0.200:8070/content/img/swiper/4.jpeg'
                    ],
                    url:'https://www.baidu.com/',
                }
            ],
        }
    },
    getAboutInfo(){
        return{
            code: 200,
            message: 'success',
            data:{                    
                id: '@increment(1)', // 得到随机id
                desc:'@cparagraph()',
                img1:"@image('120x120')",
                img2:"@image('120x200')",
                img3:"@image('120x120')",
                'subList|3':[
                    {
                        title: '@ctitle(10, 20)', 
                        'points|2':[
                            '@ctitle(20, 30)'
                        ]
                    }
                ],
            },
        }
    },
    getCaseIntroduction(){
        return{
            code: 200,
            message: 'success',
            'data|4':[
                {                    
                    id: '@increment(1)', // 得到随机id
                    title: '@ctitle(10, 20)', // 随机生成中文名字
                    desc:'@ctitle(30, 50)',
                    img:"http://192.168.0.200:8070/content/img/swiper/4.jpeg",//"@image('1200x420')",
                    url:'https://www.baidu.com/',
                }
            ],
        }
    },
    getCaseIntroduction2(){
        return{
            code: 200,
            message: 'success',
            'data|6':[
                {                    
                    'id|+1': 1, // 自增id // 得到随机id
                    title: '@ctitle(5, 10)', // 随机生成中文名字
                    desc:'@ctitle(40, 50)',
                    img:"@image('300x300')",
                    url:'https://www.baidu.com/',
                }
            ],
        }
    },

    getFriendLinks(){
        return{
            code: 200,
            message: 'success',
            'data|8':[
                {                    
                    'id|+1': 1, // 自增id // 得到随机id
                    name: '@ctitle(5, 10)', // 随机生成中文名字
                    url:'https://www.baidu.com/',
                }
            ],
        }
    }
    
} 