<template>
    <div class="home">
      
      <!-- <component is="swipper"></component> -->
    <div v-for="(item,index) in modulesData">
      <component :is="item.name"></component>
    </div>
    </div>
  </template>
  
  
  <script>
   import components from '../utils/moudules'
  
  export default {
    name: 'Home',
    components:components,
    data(){
      return{      
        modulesData:[]
      }
    },
    // watch:{
    //   $route:{
    //     immediate: true,
    //     handler() {
    //       console.log(22222);
    //       this.getModulesData();
    //     }
    //   }
    // },
    created(){
      this.getModulesData();
    },
    methods:{
        getModulesData(){
          console.log(22222);
          let _this=this;
          let routeName= this.$route.name;
          console.log(routeName);
          this.$service.get('getPageModules',{routeName:routeName})
          .then(resp=>{
            console.log("main modules", resp);
            _this.modulesData=resp.data;
            // console.log(resp.data);
          })
          // setTimeout(() => {
          //   if(routeName=='home'){            
          //      _this.modulesData=[{name:'swipper'}]
          //   }else{
          //     _this.modulesData=[{name:'abount'}]
          //   }
          // }, 2000);
        }
    }
  }
  </script>
  