<template>
  <a-layout id="components-layout-demo-top-side">
    <a-layout-header class="header" :style="{ position: 'fixed', zIndex: 1, width: '100%' }">
      <sk-header/>
    </a-layout-header>
    <a-layout-content>
        <router-view/>
    </a-layout-content>
    <sk-links/>
    <sk-footer/>
  </a-layout>
  <!-- <sk-footer/> -->
  <!-- <div style="color:#fff;">111111</div> -->
</template>
<script>
import SkFooter from './sk-footer.vue'
import SkHeader from './sk-header.vue'
import SkLinks from './sk-links.vue'
export default {
    components:{SkFooter,SkHeader,SkLinks}
}
</script>

<style>
/* .header{display: flex;justify-content: space-between;} */
#components-layout-demo-top-side .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  /* margin: 16px 28px 16px 0;*/
  float: left; 
  margin-top: 16px;
}
</style>