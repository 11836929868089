<template>
    <div class="footer-wrap">      
        <a-row type="flex" justify="center">
            <a-col class="footer-item" :md="4">
                <h2>公司简介</h2>
                <div class="item-line">
                    <a href="#" target="_blank">简介1</a>
                </div>
                <div class="item-line">
                    <a href="#" target="_blank">简介2</a>
                </div>
                <div class="item-line">
                    <a href="#" target="_blank">简介3</a>
                </div>
                <div class="item-line">
                    <a href="#" target="_blank">简介4</a>
                </div>
                <div class="item-line">
                    <a href="#" target="_blank">简介5</a>
                </div>
            </a-col>
            <a-col class="footer-item" :md="4">
                <h2>公司简介</h2>
                <div class="item-line">
                    <a href="#" target="_blank">简介1</a>
                </div>
                <div class="item-line">
                    <a href="#" target="_blank">简介2</a>
                </div>
                <div class="item-line">
                    <a href="#" target="_blank">简介3</a>
                </div>
                <div class="item-line">
                    <a href="#" target="_blank">简介4</a>
                </div>
                <div class="item-line">
                    <a href="#" target="_blank">简介5</a>
                </div>
            </a-col>
            <a-col class="footer-item" :md="4">
                <h2>公司简介</h2>
                <div class="item-line">
                    <a href="#" target="_blank">简介1</a>
                </div>
                <div class="item-line">
                    <a href="#" target="_blank">简介2</a>
                </div>
                <div class="item-line">
                    <a href="#" target="_blank">简介3</a>
                </div>
                <div class="item-line">
                    <a href="#" target="_blank">简介4</a>
                </div>
                <div class="item-line">
                    <a href="#" target="_blank">简介5</a>
                </div>
            </a-col>
            <a-col class="footer-item" :md="4">
                <h2>公司简介</h2>
                <div class="item-line">
                    <a href="#" target="_blank">简介1</a>
                </div>
                <div class="item-line">
                    <a href="#" target="_blank">简介2</a>
                </div>
                <div class="item-line">
                    <a href="#" target="_blank">简介3</a>
                </div>
                <div class="item-line">
                    <a href="#" target="_blank">简介4</a>
                </div>
                <div class="item-line">
                    <a href="#" target="_blank">简介5</a>
                </div>
            </a-col>
        </a-row>
        <!-- <a-layout-footer style="text-align: center">
          Ant Design ©2018 Created by Ant UED
        </a-layout-footer> -->
    </div>
    
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    .footer-wrap{
        padding: 40px;
        background-color: #202020;
        .footer-item{
            padding: 0 20px;
            .item-line{margin: 10px 0;}
            a{color: #949494;}
            h2{color: #fff;margin-bottom: 20px;font-size: 16px;}
        }
    }
</style>>
