import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout'
import main from '../views/main.vue'
import api from '../api'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    redirect: '/home',
    component: Layout,
    children:[
      // {
      //   path: '/home',
      //   name: 'home',
      //   component: Home
      // },
      // {
      //   path: '/about',
      //   name: 'About',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      // }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

let oneRun=true;
router.beforeEach(async (to, form, next) => {
  if(oneRun){
    oneRun=false;
    let asyncRouters=[];
    if(store.state.asynRouters.length>0){
      asyncRouters=store.state.asynRouters
    }else{      
      let resp=  await api.get('getRoutes');
      asyncRouters=resp.data;
      store.commit("setAsynRouters",asyncRouters);
    }
    asyncRouters.forEach(element => {
        router.addRoute('Layout', {
          path: element.path,
            name: element.name,
            component: main
        })      
      });
      //路由添加完成后再动态添加404，解决刷新后页面跳404和路由找不到的时候跳404
      router.addRoute({
        path:'*',
        redirect:'/404'
      })
      next({ ...to, replace: true })
  }    
  next();
  
})

export default router
