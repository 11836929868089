import axios from 'axios';

const apiPrefix = process.env.VUE_APP_API_Prefix;
// 创建一个Axios实例
const instance = axios.create({
  baseURL: apiPrefix,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// 请求拦截器
instance.interceptors.request.use(config => {
  // 在发送请求之前做些什么
  // 可以在此处添加token等认证信息
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 响应拦截器
instance.interceptors.response.use(response => {
  // 对响应数据做些什么
  return response.data;
}, error => {
  // 对响应错误做些什么
  return Promise.reject(error);
});

// 封装GET请求
const get= function (url, params = {}) {
    return instance.get(url, {
        params
      });
}

// 封装POST请求
const post= function (url, data = {}) {
    return instance.post(url, data);
}

// 封装PUT请求
const put= function (url, data = {}) {
  return instance.put(url, data);
}

// 封装DELETE请求
const del= function (url) {
  return instance.delete(url);
}
export default {
    post, get, put, del
}