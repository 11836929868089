<template>
    <div class="module-cont-box link-wrap second-bg">      
        <h2><span>友情链接</span> </h2>
        <a-row type="flex" justify="center">
            <a-col v-for="(item,index) in dataList" :key="index" :md="6">
                <div class="link-item">
                    <a :href="item.link" target="_blank"> <span>{{item.title}}</span></a>
                </div>
            </a-col>
            
        </a-row>
    </div>
    
</template>

<script>
export default {
    data(){
        return{
            dataList:[]
        }
    },
    created(){
        this.loadData();
    },
    methods:{
        loadData(){
            // this.$service.get("getFriendLinks").then(resp=>{
            this.$service.Ers76.listNav({"status":1,"type":2}).then(resp=>{
                this.dataList=resp.data;
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .link-wrap{
        h2{
            text-align: center;margin-bottom:30px; 
            span{border-bottom: 2px solid @primary-color;padding-bottom: 3px;color: @primary-color;font-weight: bold;}
        }
        .link-item{
            font-size: 14px; background-color: @bg;text-align: center;margin: 15px 10px;padding: 15px; border-radius: 3px;
        }
    }
    .dark{
        .link-item{background-color: @dark-bg-secondary;}
        .link-wrap{border-top: 1px solid @dark-bg-secondary;}
    }
    .light{
        span{color:#888}
    }
</style>
