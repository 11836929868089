import request from "./request";
import modules from "./modules";
const BASE_URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_BASE_URL : process.env.API_PROXY_PREFIX;
const get=function(url,data){
    // console.log("get", url, modules[url], data);
    if(data && data.status){
        return new Promise((resolve, reject) => {
            // console.log("if",url, data)
            request.get(url, data)        
            .then(res => {        
                resolve(res);        
            })        
            .catch(err => {            
                reject(err)        
            })    
        });
    }
    else {
        return new Promise((resolve, reject) => {
            // console.log("else",modules[url], data)  
            request.get(modules[url], data)        
            .then(res => {        
                resolve(res);        
            })        
            .catch(err => {            
                reject(err)        
            })    
        });
    }
}
const post=function(urlKey,data){
    return new Promise((resolve, reject) => {         
        request.post(modules[urlKey], data)        
        .then(res => {       
            resolve(res);        
        })        
        .catch(err => {            
            reject(err)        
        })    
    });
}
const Ers76 = {
    listNav(data) {
        // console.log("api.Ers76.listNav", data)
        // return get("/76/76/exem/n76/list.Iverson", data);
        return get("nav1.json", data);
    },

    getContent(data) {
        // return get("/76/76/exem/c76/get.Iverson", data);
        return get("getc_" + data.did + ".json", data);
    },

    listContent(data) {
        // return get("/76/76/exem/rsc76/list.Iverson", data);
        return get("listc_" + data.sid + ".json", data);
    },

    listSwipper(data) {
        return get("swipper.json", data);
    },
}
export default{
    get,post,Ers76
}